.doughnutchart {
  position: relative;
  width: 500px;
}

/* .doughnutchart-title {
  font-weight: 500;
  letter-spacing: 1.5px;
  font-size: 15px;
  color: #313030;
  margin-top: 0.5em;
  margin-bottom: 2em;
  text-align: center;
} */

.doughtnut-center {
  position: absolute;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  top: 55%;
  left: 50%;
  font-weight: 500;
  font-size: 34px;
  color: #313030;
  transform: translate(-50%, -50%);
}

.doughtnut-center span:nth-child(1) {
  font-size: 16px;
}

.doughtnut-center.events {
  position: absolute;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  top: 41%;
  left: 50%;
  font-weight: 500;
  font-size: 32px;
  color: #313030;
  transform: translate(-50%, -50%);
}

.doughtnut-center.events span:nth-child(1) {
  font-size: 14px;
}

.doughtnut-center .total-doughnutchart {
  flex: 1;
  align-items: center;
}

.carousel .control-dots {
  position: relative !important;
  bottom: -13px !important;
}

.carousel .control-dots .dot {
  background-color: #313030 !important;
}

.carousel .slide {
  background-color: inherit !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #313030 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #313030 !important;
}

.carousel.carousel-slider .control-arrow {
  padding: 0px !important;
}

.carousel .control-prev.control-arrow {
  left: -5px !important;
}

.carousel .control-next.control-arrow {
  right: -6px !important;
}
